import { flushSync } from 'react-dom';
import './App.css';
import Logo from './Component/favicon.jpg';

function App() {
  return (
    <>
      <div style={{
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
          margin: 0,        // Remove default margin
        position: 'absolute', // Optional for full-page centering
        top: 0, left: 0, bottom: 0, right: 0  // Ensure the container spans the whole screen
      }}>
        <img src={Logo} alt="Logo" style={{ height: '70vh'}}/>
      </div>
    </>
  );
}

export default App;
